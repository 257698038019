<template>
  <div class="marketing-block row full-width column-mobile g-32">
    <div v-if="!noCounter" class="full-width">
      <TimerCampaignBlock v-if="counter" :title="counter.title" :date="counter.date"/>
    </div>
    <div v-if="banners.length" class="full-width" v-for="i in bannerSlots">
      <template v-if="!banners[i]">
        <div></div>
      </template>
      <template v-else>
        <MiniBanner :url="banners[i].url" :unit="banners[i].unit" :price="banners[i].price" :src="banners[i].image"/>
      </template>
    </div>
  </div>
</template>

<script>
import MiniBanner from "@/components/molecules/MiniBanner.vue";
import Timer from "@/components/molecules/Timer.vue";
import TimerCampaignBlock from "@/components/molecules/TimerCampaignBlock.vue";

export default {
  components: {TimerCampaignBlock, Timer, MiniBanner},
  props: {
    bannerSlots: {
      type: Array,
      default: () => [0, 1, 2]
    },
    banners: {
      type: [Array, Object],
      default: () => []
    },
    counter: {
      type: Object || null,
    },
    noCounter: {
      type: Boolean,
      default: false
    }
  },
  setup() {
    return {}
  }
}
</script>
